import React, { useState } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';

import eyeOpen from '../images/eye.png';
import eyeClosed from '../images/eye-closed.png';

const SignUp = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/');
  }

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleKeyDown = (event, toggleVisibility) => {
    if (event.key === 'Enter') {
        toggleVisibility();
    }
  };

  return (
    <div className="main-body flex justify-between">
      <div className="form-body">
        <div className="form-body-container">
          <header>
            <Link to="/" className="logo">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <section id="form" className="form">
            <form onSubmit={handleSubmit} id="signForm">
              <h1>Sign Up</h1>
              <p>
                To continue using the app, register by filling out the forms
                below.
              </p>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  required
                  placeholder="First Name"
                />
              </div>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  required
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="email@example.com"
                />
              </div>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="password">Password</label>
                <div className="password-wrap">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    required
                    name="password"
                    placeholder=""
                  />
                  <img
                    id="eye"
                    src={passwordShown ? eyeClosed : eyeOpen}
                    onClick={togglePasswordVisibility}
                    onKeyDown={(event) => handleKeyDown(event, togglePasswordVisibility)}
                    className="eye"
                    tabIndex={0}
                    alt=""
                  />
                </div>
              </div>
              <div className="form-group flex justify-start">
                <input
                  className="checkbox"
                  type="checkbox"
                  required
                  name="terms"
                  id="terms"
                />
                <span>
                  <label htmlFor="terms">Agree to </label>
                  <a href="#" className="bold">
                    Terms &amp; Conditions
                  </a>{" "}
                  and
                  {" "}<a href="#" className="bold">
                    Privacy Policy.
                  </a>
                </span>
              </div>
              <div className="form-group">
                <button type="submit" className="btn">
                  Sign up
                </button>
              </div>
              <div className="form-group from-btn-small">
                <Link to="/sign-in">Sign In</Link>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="form-image flex align-center justify-center">
        {/* <img src="./images/app-homepage1.png" alt="App homepage" /> */}
      </div>
    </div>
  );
};

export default SignUp;
