import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

const Transactions = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  const [showTransaction, setShowTransaction] = useState(false);

  const handleKeyDownTransaction = (event) => {
    if (event.key === 'Enter') {
        setShowTransaction(true);
    }
  };

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage="transactions"></Navbar>
          <NavbarMobile activePage="transactions"></NavbarMobile>
        </div>
      </div>
      <main className="main">
        {/* ----- Modals (Transaction Detail) ------ */}
        <section id="transaction-details" className="modal-container" style={{ transform: showTransaction ? 'translateX(0)' : 'translateX(100%)', display: showTransaction ? 'block' : 'none' }}>
          <div className="upper">
            <header>
              <div onClick={() => setShowTransaction(false)} id="back-btn" className="back-btn flex align-center" role="button" tabindex="0">
                <img src="images/arrow-back.svg" /> Go Back
              </div>
            </header>
            <div className="flex flex-col justify-center gap-m align-center">
              <img id="transaction-icon" src="images/coffee.svg" />
              <h3 id="transaction-name">Coffee Shop</h3>
              <p id="transaction-date">10 Jul, 2023</p>
              <span id="transaction-status" className="tx-status">
                Pending
              </span>
            </div>
          </div>
          <div className="lower">
            <div className="tx-income card-bordered flex flex-col align-center justify-between">
              <h2 id="transaction-amount">$10</h2>
              <span className="outcome flex align-center gap-sm">
                <img
                  id="transaction-ticon"
                  src="images/trend-down.svg"
                />
                <span id="transaction-type" className="bold">
                  Outcome
                </span>
              </span>
            </div>
            <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
              <img src="images/left-balance.svg" />
              <p>
                <span className="opacity-05">Left Balance:</span>
                <span className="bold opacity-1">&nbsp; $242,456</span>
              </p>
            </div>
            <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
              <img src="images/apple.svg" />
              <p>
                <span className="opacity-05">Payment Method:</span>
                <span className="bold opacity-1">&nbsp; Apple Pay</span>
              </p>
            </div>
            <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
              <img src="images/location.svg" />
              <p>
                <span className="opacity-05">Address:</span>
                <span className="bold opacity-1">
                  &nbsp; 1901 Thornridge Cir. Shiloh
                </span>
              </p>
            </div>
          </div>
        </section>
        {/* Modal End */}
        <header className="main-head flex justify-between align-center">
          <h1>Transactions</h1>
          <div className="dropdown profile-avatar">
            <div onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l" role="button" tabindex="0">
              <img src="images/profile-pic.svg" />
            </div>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex flex-col justify-between">
          <header className="table-header flex align-center justify-between">
            <h4 className="all-tx-text">All Transactions</h4>
            <div className="filter-btns flex align-center gap-2xl">
              <div className="status flex flex-col">
                <label
                  style={{ fontWeight: 500, fontSize: "0.9rem" }}
                  htmlFor="status"
                >
                  Status
                </label>
                <select style={{ marginTop: 6 }} name="status" id="status">
                  <option value="#" selected="" disabled="">
                    Select Status
                  </option>
                  <option value="completed">Completed</option>
                  <option value="pending">Pending</option>
                  <option value="closed">Closed</option>
                </select>
              </div>
              <div className="type flex flex-col">
                <label
                  style={{ fontWeight: 500, fontSize: "0.9rem" }}
                  htmlFor="type"
                >
                  Type
                </label>
                <select style={{ marginTop: 6 }} name="type" id="type">
                  <option value="#" selected="" disabled="">
                    Select Type
                  </option>
                  <option value="volvo">Income</option>
                  <option value="saab">Outcome</option>
                </select>
              </div>
            </div>
          </header>
          <table className="recent-transactions transactions-pg-table">
            <thead>
              <tr>
                <th className="name">Name</th>
                <th className="status mobile-hide">Status</th>
                <th className="type mobile-hide">Type</th>
                <th className="date-tx mobile-hide">Date</th>
                <th className="amount-tx">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Airplane Tickets"
                data-date="10 Jul, 2023"
                data-amount="$2,456"
                data-icon="images/airplane.svg"
                data-status="Pending"
                data-type="Income"
                data-ticon="images/trend-up.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/airplane.svg" />
                  <span className="bold amount">Airplane Tickets</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-up.svg" />
                    <span>Income </span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">10 Jul, 2023</td>
                <td className="bold amount-tx">$2,456</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Coffee Shop"
                data-date="10 Jul, 2023"
                data-amount="$10"
                data-icon="images/airplane.svg"
                data-status="Pending"
                data-type="Outcome"
                data-ticon="images/trend-down.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/coffee.svg" />
                  <span className="bold amount">Coffee Shop</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="outcome flex align-center gap-sm">
                    <img src="images/trend-down.svg" />
                    <span>Outcome</span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">10 Jul, 2023</td>
                <td className="bold amount-tx">$10</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Marie M."
                data-date="11 Jul, 2023"
                data-amount="$20"
                data-icon="images/marie.svg"
                data-status="Completed"
                data-type="Income"
                data-ticon="images/trend-up.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/marie.svg" />
                  <span className="bold amount">Marie M.</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status tx-completed">Completed</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-up.svg" />
                    <span>Income </span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">11 Jul, 2023</td>
                <td className="bold amount-tx">$20</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Gym"
                data-date="11 Jul, 2023"
                data-amount="$300"
                data-icon="images/gym.svg"
                data-status="Completed"
                data-type="Income"
                data-ticon="images/trend-up.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/gym.svg" />
                  <span className="bold amount">Gym</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status tx-completed">Completed</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-up.svg" />
                    <span>Income </span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">10 Jul, 2023</td>
                <td className="bold amount-tx">$300</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Gas"
                data-date="11 Jul, 2023"
                data-amount="$39"
                data-icon="images/gas.svg"
                data-status="Pending"
                data-type="Outcome"
                data-ticon="images/trend-down.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/gas.svg" />
                  <span className="bold amount">Gas</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status tx-cancelled">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-down.svg" />
                    <span>Outcome</span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">11 Jul, 2023</td>
                <td className="bold amount-tx">$39</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Coffee Shop"
                data-date="10 Jul, 2023"
                data-amount="$10"
                data-icon="images/airplane.svg"
                data-status="pending"
                data-type="Outcome"
                data-ticon="images/trend-down.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/coffee.svg" />
                  <span className="bold amount">Coffee Shop</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="outcome flex align-center gap-sm">
                    <img src="images/trend-down.svg" />
                    <span>Outcome</span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">10 Jul, 2023</td>
                <td className="bold amount-tx">$10</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Coffee Shop"
                data-date="10 Jul, 2023"
                data-amount="$10"
                data-icon="images/airplane.svg"
                data-status="pending"
                data-type="Outcome"
                data-ticon="images/trend-down.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/coffee.svg" />
                  <span className="bold amount">Coffee Shop</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="outcome flex align-center gap-sm">
                    <img src="images/trend-down.svg" />
                    <span>Outcome</span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">10 Jul, 2023</td>
                <td className="bold amount-tx">$10</td>
              </tr>
              <tr
                tabIndex={0}
                onClick={() => setShowTransaction(true)}
                onKeyDown={(event) => handleKeyDownTransaction(event)}
                data-name="Gas"
                data-date="11 Jul, 2023"
                data-amount="$39"
                data-icon="images/gas.svg"
                data-status="Pending"
                data-type="Outcome"
                data-ticon="images/trend-down.svg"
              >
                <td className="flex align-center gap-sm name">
                  <img src="images/gas.svg" />
                  <span className="bold amount">Gas</span>
                </td>
                <td className="status mobile-hide">
                  <div className="flex">
                    <span className="tx-status tx-cancelled">Pending</span>
                  </div>
                </td>
                <td className="type mobile-hide">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-down.svg" />
                    <span>Outcome</span>
                  </div>
                </td>
                <td className="date-tx mobile-hide">11 Jul, 2023</td>
                <td className="bold amount-tx">$39</td>
              </tr>
            </tbody>
            <tbody />
          </table>
        </div>
      </main>
    </div>
  );
};

export default Transactions;
