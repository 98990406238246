import React, { useState } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';

import eyeOpen from '../images/eye.png';
import eyeClosed from '../images/eye-closed.png';


const RecoverPassword = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/');
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const handleKeyDown = (event, toggleVisibility) => {
    if (event.key === 'Enter') {
        toggleVisibility();
    }
  };

  return (
    <div className="main-body signIn flex justify-between">
      <div className="form-body">
        <div className="form-body-container">
          <header>
            <Link to="/forgot-password"
              className="back-btn flex align-center"
            >
              <img src="./images/arrow-back.png" /> Go Back
            </Link>
          </header>
          <section id="form" className="form">
            <form onSubmit={handleSubmit} id="recoverPwForm">
              <h1>Password recovery.</h1>
              <p>Your email is confirmed. You can now enter a new password.</p>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="new_password">New Password</label>
                <div className="password-wrap">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="new_password"
                    name="new_password"
                    required
                    placeholder=""
                  />
                  <img
                    id="eye"
                    src={passwordShown ? eyeClosed : eyeOpen}
                    onClick={togglePasswordVisibility}
                    onKeyDown={(event) => handleKeyDown(event, togglePasswordVisibility)}
                    className="eye"
                    tabIndex={0}
                  />
                </div>
              </div>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="confirm_password">Confirm Password</label>
                <div className="password-wrap">
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    required
                    placeholder=""
                  />
                  <img
                    id="confirm_eye"
                    src={confirmPasswordShown ? eyeClosed : eyeOpen} 
                    className="eye"
                    onClick={toggleConfirmPasswordVisibility}
                    onKeyDown={(event) => handleKeyDown(event, toggleConfirmPasswordVisibility)}
                    tabIndex={0}
                  />
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn">
                  Recover the Password
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="form-image flex align-center justify-center">
        {/* <img src="./images/app-homepage1.png" /> */}
      </div>
    </div>
  );
};

export default RecoverPassword;
