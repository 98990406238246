import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

const Support = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  const [showAnswerOne, setShowAnswerOne] = useState(true);
  const [showAnswerTwo, setShowAnswerTwo] = useState(false);
  const [showAnswerThree, setShowAnswerThree] = useState(false);
  const [showAnswerFour, setShowAnswerFour] = useState(false);
  const [showAnswerFive, setShowAnswerFive] = useState(false);

  const handleKeyDownAnswerOne = (event) => {
    if (event.key === 'Enter') {
        setShowAnswerOne(!showAnswerOne)
    }
  };

  const handleKeyDownAnswerTwo = (event) => {
    if (event.key === 'Enter') {
        setShowAnswerTwo(!showAnswerTwo)
    }
  };

  const handleKeyDownAnswerThree = (event) => {
    if (event.key === 'Enter') {
        setShowAnswerThree(!showAnswerThree)
    }
  };

  const handleKeyDownAnswerFour = (event) => {
    if (event.key === 'Enter') {
        setShowAnswerFour(!showAnswerFour)
    }
  };

  const handleKeyDownAnswerFive = (event) => {
    if (event.key === 'Enter') {
        setShowAnswerFive(!showAnswerFive)
    }
  };
  
  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage="support"></Navbar>
          <NavbarMobile activePage="support"></NavbarMobile>
        </div>
      </div>
      <main className="main">
        <header className="main-head flex justify-between align-center">
          <h1>Transactions</h1>
          <div className="dropdown profile-avatar">
          <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l" aria-label="Toggle profile options">
              <img src="images/profile-pic.svg" alt="" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex flex-col justify-between">
          <header className="table-header flex align-center justify-between">
            <h4>Frequently Asked Questions</h4>
          </header>
          <section className="faq-section">
            <div className="faq-item active">
              <button
                className="faq-question active flex align-center justify-between"
                onClick={() => {
                  setShowAnswerOne(!showAnswerOne);
                }}
                onKeyDown={(event) => handleKeyDownAnswerOne(event)}
                tabIndex={0}
              >
                <span>Question 1?</span> <img src="./images/add.svg" alt="Expand" />
              </button>
              <div style={{ display: showAnswerOne ? 'block' : 'none' }} className="faq-answer">
                <p>
                  This is the answer to Question 1.
                </p>
                <p>
                  1. Download and install our banking app from your device's app
                  store.
                </p>
                <p>
                  2. Open the app and select the option to create a new account.
                </p>
                <p>
                  3. Download and install our banking app from your device's app
                  store.
                </p>
                <p>
                  4. Set up a username and password for your online banking
                  account.
                </p>
                <p>
                  5. Follow any additional prompts to verify your identity and
                  complete the registration process.
                </p>
                <div className="faq-media flex align-center justify-center">
                <video
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    title="Embedded Media Player"
                    controls
                    aria-label="Video"
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
            <div className="faq-item">
              <button
                className="faq-question active flex align-center justify-between"
                onClick={() => {
                  setShowAnswerTwo(!showAnswerTwo);
                }}
                onKeyDown={(event) => handleKeyDownAnswerTwo(event)}
                tabIndex={0}
              >
                <span>Question 2?</span> <img src="./images/add.svg" alt="Expand" />
              </button>
              <div style={{ display: showAnswerTwo ? 'block' : 'none' }} className="faq-answer">
                <p>This is the answer to Question 2.</p>
              </div>
            </div>
            <div className="faq-item">
              <button
                className="faq-question active flex align-center justify-between"
                onClick={() => {
                  setShowAnswerThree(!showAnswerThree);
                }}
                onKeyDown={(event) => handleKeyDownAnswerThree(event)}
                tabIndex={0}
              >
                <span>Question 3?</span> <img src="./images/add.svg" alt="Expand" />
              </button>
              <div style={{ display: showAnswerThree ? 'block' : 'none' }} className="faq-answer">
                <p>This is the answer to Question 3.</p>
              </div>
            </div>
            <div className="faq-item">
              <button
                className="faq-question active flex align-center justify-between"
                onClick={() => {
                  setShowAnswerFour(!showAnswerFour);
                }}
                onKeyDown={(event) => handleKeyDownAnswerFour(event)}
                tabIndex={0}
              >
                <span>Question 4?</span> <img src="./images/add.svg" alt="Expand" />
              </button>
              <div style={{ display: showAnswerFour ? 'block' : 'none' }} className="faq-answer">
                <p>This is the answer to Question 4.</p>
              </div>
            </div>
            <div className="faq-item">
              <button
                className="faq-question active flex align-center justify-between"
                onClick={() => {
                  setShowAnswerFive(!showAnswerFive);
                }}
                onKeyDown={(event) => handleKeyDownAnswerFive(event)}
                tabIndex={0}
              >
                <span>Question 5?</span> <img src="./images/add.svg" alt="Expand" />
              </button>
              <div style={{ display: showAnswerFive ? 'block' : 'none' }} className="faq-answer">
                <p>This is the answer to Question 5.</p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Support;
