import React, { useState } from "react";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";
import { Link } from "react-router-dom";

const LoanSuccess = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage=""></Navbar>
          <NavbarMobile activePage=""></NavbarMobile>
        </div>
      </div>
      <main className="main">
        <header className="main-head flex justify-between align-center">
          <h1>Loans</h1>
          <div className="dropdown profile-avatar">
            <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l">
              <img src="images/profile-pic.svg" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex flex-col justify-between">
          <section className="loans-section flex flex-col align-center gap-sm">
            <img src="images/dollar-square.svg" />
            <h4>Your application has been received successfully!</h4>
            <p style={{ width: "40%", margin: "0 auto", opacity: "0.5" }}>
              Your loan request has been received. An agent will review your
              application soon and get in touch for the next steps.
            </p>
            <Link to="/"
              id="loansSuccesfullBtn"
              style={{ width: 310, textAlign: "center" }}
              className="btn-small"
            >
              Got It!
            </Link>
          </section>
        </div>
      </main>
    </div>
  );
};

export default LoanSuccess;
