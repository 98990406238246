import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Import all versions of each page
import Dashboard_0 from "./pages/Dashboard_0";
import Dashboard_1 from "./pages/Dashboard_1";
import Dashboard_2 from "./pages/Dashboard_2";
import Dashboard_3 from "./pages/Dashboard_3";
import Dashboard_99 from "./pages/Dashboard_99";
import Transactions_0 from "./pages/Transactions_0";
import Transactions_1 from "./pages/Transactions_1";
import Transactions_2 from "./pages/Transactions_2";
import Transactions_3 from "./pages/Transactions_3";
import Loans_0 from "./pages/Loans_0";
import Loans_1 from "./pages/Loans_1";
import Loans_2 from "./pages/Loans_2";
import Loans_3 from "./pages/Loans_3";
import Cards_0 from "./pages/Cards_0";
import Cards_1 from "./pages/Cards_1";
import Cards_2 from "./pages/Cards_2";
import Cards_3 from "./pages/Cards_3";
import Support_0 from "./pages/Support_0";
import Support_1 from "./pages/Support_1";
import Support_2 from "./pages/Support_2";
import Support_3 from "./pages/Support_3";
import SignUp_0 from "./pages/SignUp_0";
import SignUp_1 from "./pages/SignUp_1";
import SignUp_2 from "./pages/SignUp_2";
import SignUp_3 from "./pages/SignUp_3";
import SignIn_0 from "./pages/SignIn_0";
import SignIn_1 from "./pages/SignIn_1";
import SignIn_2 from "./pages/SignIn_2";
import SignIn_3 from "./pages/SignIn_3";
import LoanCalc_0 from "./pages/LoanCalc_0";
import LoanCalc_1 from "./pages/LoanCalc_1";
import LoanCalc_2 from "./pages/LoanCalc_2";
import LoanCalc_3 from "./pages/LoanCalc_3";
import LoanCalc_99 from "./pages/LoanCalc_99";
import ForgotPassword_0 from "./pages/ForgotPassword_0";
import ForgotPassword_1 from "./pages/ForgotPassword_1";
import ForgotPassword_2 from "./pages/ForgotPassword_2";
import ForgotPassword_3 from "./pages/ForgotPassword_3";
import RecoverPassword_0 from "./pages/RecoverPassword_0";
import RecoverPassword_1 from "./pages/RecoverPassword_1";
import RecoverPassword_2 from "./pages/RecoverPassword_2";
import RecoverPassword_3 from "./pages/RecoverPassword_3";
import LoanInfo_0 from "./pages/LoanInfo_0";
import LoanInfo_1 from "./pages/LoanInfo_1";
import LoanInfo_2 from "./pages/LoanInfo_2";
import LoanInfo_3 from "./pages/LoanInfo_3";
import LoanSuccess_0 from "./pages/LoanSuccess_0";
import LoanSuccess_1 from "./pages/LoanSuccess_1";
import LoanSuccess_2 from "./pages/LoanSuccess_2";
import LoanSuccess_3 from "./pages/LoanSuccess_3";
import Traffic from "./pages/Traffic";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const uw = queryParameters.get("uw");

  // Function to always select the _3 version if uw=y
  function selectVersion(versionList, index) {
    return uw === 'y' ? versionList[3] : versionList[Math.floor(Math.random() * versionList.length)];
  }

  const Dashboard = selectVersion([Dashboard_0, Dashboard_1, Dashboard_2, Dashboard_3], 3);
  const Transactions = selectVersion([Transactions_0, Transactions_1, Transactions_2, Transactions_3], 3);
  const Loans = selectVersion([Loans_0, Loans_1, Loans_2, Loans_3], 3);
  const Cards = selectVersion([Cards_0, Cards_1, Cards_2, Cards_3], 3);
  const Support = selectVersion([Support_0, Support_1, Support_2, Support_3], 3);
  const SignUp = selectVersion([SignUp_0, SignUp_1, SignUp_2, SignUp_3], 3);
  const SignIn = selectVersion([SignIn_0, SignIn_1, SignIn_2, SignIn_3], 3);
  const LoanCalc = LoanCalc_3;
  const ForgotPassword = selectVersion([ForgotPassword_0, ForgotPassword_1, ForgotPassword_2, ForgotPassword_3], 3);
  const RecoverPassword = selectVersion([RecoverPassword_0, RecoverPassword_1, RecoverPassword_2, RecoverPassword_3], 3);
  const LoanInfo = selectVersion([LoanInfo_0, LoanInfo_1, LoanInfo_2, LoanInfo_3], 3);
  const LoanSuccess = selectVersion([LoanSuccess_0, LoanSuccess_1, LoanSuccess_2, LoanSuccess_3], 3);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/loans" element={<Loans />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/support" element={<Support />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/loan-calc" element={<LoanCalc />} />
        <Route path="/loan-info" element={<LoanInfo />} />
        <Route path="/loan-success" element={<LoanSuccess />} />
        <Route path="/traffic" element={<Traffic />} />
      </Routes>
    </Router>
  );
}

export default App;
