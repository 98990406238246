import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ activePage }) => {
  return (
    <nav className="mobile-hide">
      <ul>
        <li>
          <Link
            to="/"
            className={
              activePage === "dashboard"
                ? "flex align-center nav-active"
                : "flex align-center"
            }
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7 16.8801H13.4C14.55 16.8801 15.49 15.9401 15.49 14.7901V14.0901H12.7V16.8801Z"
                fill="#1C1B20"
              />
              <path
                d="M8.51001 14.7901C8.51001 15.9401 9.45001 16.8801 10.6 16.8801H11.3V14.0901H8.51001V14.7901Z"
                fill="#1C1B20"
              />
              <path
                d="M8.51001 11.9999V12.6999H11.3V9.90991H10.6C9.45001 9.90991 8.51001 10.8499 8.51001 11.9999Z"
                fill="#1C1B20"
              />
              <path
                d="M20.03 6.81994L14.28 2.78994C12.71 1.68994 10.31 1.74994 8.8 2.91994L3.79 6.82994C2.78 7.60994 2 9.20994 2 10.4699V17.3699C2 19.9199 4.07 21.9999 6.61 21.9999H17.38C19.92 21.9999 21.99 19.9299 21.99 17.3799V10.5999C22 9.24994 21.13 7.58994 20.03 6.81994ZM16.88 14.7899C16.88 16.7099 15.31 18.2799 13.39 18.2799H10.6C8.68 18.2799 7.11 16.7199 7.11 14.7899V11.9999C7.11 10.0799 8.68 8.50994 10.6 8.50994H13.39C15.31 8.50994 16.88 10.0699 16.88 11.9999V14.7899Z"
                fill="#1C1B20"
              />
              <path
                d="M13.4 9.90991H12.7V12.6999H15.49V11.9999C15.49 10.8499 14.55 9.90991 13.4 9.90991Z"
                fill="#1C1B20"
              />
            </svg>

            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link
            to="/transactions"
            className={
              activePage === "transactions"
                ? "flex align-center nav-active"
                : "flex align-center"
            }
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.11 16.9C9.11 17.18 8.89 17.4 8.61 17.4H5.82C5.54 17.4 5.32 17.18 5.32 16.9V12.28C5.32 11.65 5.83 11.14 6.46 11.14H8.61C8.89 11.14 9.11 11.36 9.11 11.64V16.9ZM13.89 16.9C13.89 17.18 13.67 17.4 13.39 17.4H10.6C10.32 17.4 10.1 17.18 10.1 16.9V7.74C10.1 7.11 10.61 6.6 11.24 6.6H12.76C13.39 6.6 13.9 7.11 13.9 7.74V16.9H13.89ZM18.68 16.9C18.68 17.18 18.46 17.4 18.18 17.4H15.39C15.11 17.4 14.89 17.18 14.89 16.9V13.35C14.89 13.07 15.11 12.85 15.39 12.85H17.54C18.17 12.85 18.68 13.36 18.68 13.99V16.9Z"
                fill="#1C1B20"
              />
            </svg>

            <span>Transactions</span>
          </Link>
        </li>
        <li>
          <Link
            to="/loans"
            className={
              activePage === "loans"
                ? "flex align-center nav-active"
                : "flex align-center"
            }
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2499 8.08008V10.9401L10.2399 10.5901C9.72992 10.4101 9.41992 10.2401 9.41992 9.37008C9.41992 8.66008 9.94992 8.08008 10.5999 8.08008H11.2499Z"
                fill="#1C1B20"
              />
              <path
                d="M14.58 14.6296C14.58 15.3396 14.05 15.9196 13.4 15.9196H12.75V13.0596L13.76 13.4096C14.27 13.5896 14.58 13.7596 14.58 14.6296Z"
                fill="#1C1B20"
              />
              <path
                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 19.83 4.17 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.26 12C15.04 12.27 16.08 12.84 16.08 14.63C16.08 16.17 14.88 17.42 13.4 17.42H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V17.42H10.89C9.25 17.42 7.92 16.03 7.92 14.33C7.92 13.92 8.25 13.58 8.67 13.58C9.08 13.58 9.42 13.92 9.42 14.33C9.42 15.21 10.08 15.92 10.89 15.92H11.25V12.53L9.74 12C8.96 11.73 7.92 11.16 7.92 9.37C7.92 7.83 9.12 6.58 10.6 6.58H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V6.58H13.11C14.75 6.58 16.08 7.97 16.08 9.67C16.08 10.08 15.75 10.42 15.33 10.42C14.92 10.42 14.58 10.08 14.58 9.67C14.58 8.79 13.92 8.08 13.11 8.08H12.75V11.47L14.26 12Z"
                fill="#1C1B20"
              />
            </svg>
            <span>Loans</span>
          </Link>
        </li>
        <li>
          <Link
            to="/cards"
            className={
              activePage === "cards"
                ? "flex align-center nav-active"
                : "flex align-center"
            }
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 7.55039C22 8.21039 21.46 8.75039 20.8 8.75039H3.2C2.54 8.75039 2 8.21039 2 7.55039V7.54039C2 5.25039 3.85 3.40039 6.14 3.40039H17.85C20.14 3.40039 22 5.26039 22 7.55039Z"
                fill="#1C1B20"
              />
              <path
                d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                fill="#1C1B20"
              />
            </svg>

            <span>Cards</span>
          </Link>
        </li>
        <li>
          <Link
            to="/support"
            className={
              activePage === "support"
                ? "flex align-center nav-active"
                : "flex align-center"
            }
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 2.43018H7C4 2.43018 2 4.43018 2 7.43018V13.4302C2 16.4302 4 18.4302 7 18.4302V20.5602C7 21.3602 7.89 21.8402 8.55 21.3902L13 18.4302H17C20 18.4302 22 16.4302 22 13.4302V7.43018C22 4.43018 20 2.43018 17 2.43018ZM12 14.6002C11.58 14.6002 11.25 14.2602 11.25 13.8502C11.25 13.4402 11.58 13.1002 12 13.1002C12.42 13.1002 12.75 13.4402 12.75 13.8502C12.75 14.2602 12.42 14.6002 12 14.6002ZM13.26 10.4502C12.87 10.7102 12.75 10.8802 12.75 11.1602V11.3702C12.75 11.7802 12.41 12.1202 12 12.1202C11.59 12.1202 11.25 11.7802 11.25 11.3702V11.1602C11.25 10.0002 12.1 9.43018 12.42 9.21018C12.79 8.96018 12.91 8.79018 12.91 8.53018C12.91 8.03018 12.5 7.62018 12 7.62018C11.5 7.62018 11.09 8.03018 11.09 8.53018C11.09 8.94018 10.75 9.28018 10.34 9.28018C9.93 9.28018 9.59 8.94018 9.59 8.53018C9.59 7.20018 10.67 6.12018 12 6.12018C13.33 6.12018 14.41 7.20018 14.41 8.53018C14.41 9.67018 13.57 10.2402 13.26 10.4502Z"
                fill="#1C1B20"
              />
            </svg>

            <span>Support</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
