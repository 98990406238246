import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

const LoanCalc = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  const [selectedLoanLength, setSelectedLoanLength] = useState('');

  const handleLoanLengthChange = (event) => {
    setSelectedLoanLength(event.target.value);
  };

  const [selectedLoanAmount, setSelectedLoanAmount] = useState(0);

  const handleChangeLoanAmount = (event) => {
    setSelectedLoanAmount(event.target.value);
  };


  const handleKeyDownLoanAmount = (event, selectedLoanAmount) => {
    if (event.key === 'Enter') {
      setSelectedLoanAmount(selectedLoanAmount);
    }
  };

  const handleKeyDownLoanLength = (event, selectedLoanLength) => {
    if (event.key === 'Enter') {
      setSelectedLoanLength(selectedLoanLength);
    }
  };

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage=""></Navbar>
          <NavbarMobile activePage=""></NavbarMobile>
        </div>
      </div>
      <main className="main">
        <header className="main-head flex justify-between align-center">
          <h1>Loans</h1>
          <div className="dropdown profile-avatar">
            <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l">
              <img src="images/profile-pic.svg" alt="" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex justify-between">
          <section className="loans-calculator card-bordered">
            <header>
              <h4>Loan Calculator</h4>
            </header>
            <section className="loan-amount loan-div border-b-gray">
              <h5>Amount in dollars</h5>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="last_name">Loan Amount:</label>
                <div className="search-wrap">
                  <div className="input-wrap flex align-center card-bordered">
                    <img src="images/dollar.svg" alt="" />
                    <input
                    required
                    type="text"
                    id="loan-amount-input"
                    placeholder={0}
                    value={selectedLoanAmount}
                    onChange={handleChangeLoanAmount}
                    />
                  </div>
                </div>
                <div className="loan-amount-options flex">
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 500)} tabIndex={0} onClick={() => setSelectedLoanAmount(500)} className={`loan-amount-option ${selectedLoanAmount == 500 ? 'selected' : ''}`}>500</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 1000)} tabIndex={0} onClick={() => setSelectedLoanAmount(1000)} className={`loan-amount-option ${selectedLoanAmount == 1000 ? 'selected' : ''}`}>1,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 2000)} tabIndex={0} onClick={() => setSelectedLoanAmount(2000)} className={`loan-amount-option ${selectedLoanAmount == 2000 ? 'selected' : ''}`}>2,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 5000)} tabIndex={0} onClick={() => setSelectedLoanAmount(5000)} className={`loan-amount-option ${selectedLoanAmount == 5000 ? 'selected' : ''}`}>5,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 7500)} tabIndex={0} onClick={() => setSelectedLoanAmount(7500)} className={`loan-amount-option ${selectedLoanAmount == 7500 ? 'selected' : ''}`}>7,500</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 10000)} tabIndex={0} onClick={() => setSelectedLoanAmount(10000)} className={`loan-amount-option ${selectedLoanAmount == 10000 ? 'selected' : ''}`}>10,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 20000)} tabIndex={0} onClick={() => setSelectedLoanAmount(20000)} className={`loan-amount-option ${selectedLoanAmount == 20000 ? 'selected' : ''}`}>20,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 25000)} tabIndex={0} onClick={() => setSelectedLoanAmount(25000)} className={`loan-amount-option ${selectedLoanAmount == 25000 ? 'selected' : ''}`}>25,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 50000)} tabIndex={0} onClick={() => setSelectedLoanAmount(50000)} className={`loan-amount-option ${selectedLoanAmount == 50000 ? 'selected' : ''}`}>50,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 100000)} tabIndex={0} onClick={() => setSelectedLoanAmount(100000)} className={`loan-amount-option ${selectedLoanAmount == 100000 ? 'selected' : ''}`}>100,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 200000)} tabIndex={0} onClick={() => setSelectedLoanAmount(200000)} className={`loan-amount-option ${selectedLoanAmount == 200000 ? 'selected' : ''}`}>200,000</span>
                  <span onKeyDown={(event) => handleKeyDownLoanAmount(event, 250000)} tabIndex={0} onClick={() => setSelectedLoanAmount(250000)} className={`loan-amount-option ${selectedLoanAmount == 250000 ? 'selected' : ''}`}>250,000</span>
                </div>
              </div>
            </section>
            <section className="loan-period loan-div border-b-gray">
              <h5>Period</h5>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="last_name">Loan Length:</label>
                <div className="select-container">
                  <select required value={selectedLoanLength} onChange={handleLoanLengthChange} className="custom-select" id="loan-length-select">
                    <option value="">
                      Select loan length
                    </option>
                    <option value={1}>1 month</option>
                    <option value={2}>2 months</option>
                    <option value={12}>1 year</option>
                    <option value={24}>2 years</option>
                    <option value={36}>3 years</option>
                    <option value={48}>4 years</option>
                    <option value={60}>5 years</option>
                  </select>
                  <div className="dropdown-icon">
                    <img src="images/arrow-down.svg" alt="" />
                  </div>
                </div>
                
                <div className="loan-amount-options flex">
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 1)} tabIndex={0} onClick={() => setSelectedLoanLength(1)} className={`loan-period-option ${selectedLoanLength == 1 ? 'selected' : ''}`} data-value={1}>
                    1 month
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 2)} tabIndex={0} onClick={() => setSelectedLoanLength(2)} className={`loan-period-option ${selectedLoanLength == 2 ? 'selected' : ''}`} data-value={2}>
                    2 months
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 12)} tabIndex={0} onClick={() => setSelectedLoanLength(12)} className={`loan-period-option ${selectedLoanLength == 12 ? 'selected' : ''}`} data-value={12}>
                    1 year
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 24)} tabIndex={0} onClick={() => setSelectedLoanLength(24)} className={`loan-period-option ${selectedLoanLength == 24 ? 'selected' : ''}`} data-value={24}>
                    2 years
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 36)} tabIndex={0} onClick={() => setSelectedLoanLength(36)} className={`loan-period-option ${selectedLoanLength == 36 ? 'selected' : ''}`} data-value={36}>
                    3 years
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 48)} tabIndex={0} onClick={() => setSelectedLoanLength(48)} className={`loan-period-option ${selectedLoanLength == 48 ? 'selected' : ''}`} data-value={48}>
                    4 years
                  </span>
                  <span onKeyDown={(event) => handleKeyDownLoanLength(event, 60)} tabIndex={0} onClick={() => setSelectedLoanLength(60)} className={`loan-period-option ${selectedLoanLength == 60 ? 'selected' : ''}`} data-value={60}>
                    5 years
                  </span>
                </div>
              </div>
            </section>
            <section className="loan-interest loan-div border-b-gray">
              <h5>Per Month:</h5>
              <div className="per-month flex align-end justify-between">
                <h2>$1280</h2>
                <p>
                  You will pay: <span className="bold">$30,737</span>
                </p>
              </div>
              <Link to="/loan-info">
              <button id="applyLoanBtn" className="btn">
                Apply Now
              </button>
              </Link>
            </section>
          </section>
          <section className="loans-information">
            <header>
              <h4>Loan Information</h4>
            </header>
            <section className="loan-insights flex flex-col">
              <div className="loan-insights-data flex align-center justify-between">
                <div>
                  <h5>2 years</h5>
                  <p>
                    Regular payment: <span className="bold">$1280</span>
                  </p>
                </div>
                <div>
                  <h5>%5</h5>
                  <p>Interest rate</p>
                </div>
              </div>
              <img
                className="loan-insights-graph"
                src="images/graph.svg"
                alt=""
              />
              <div className="graph-points flex align-center justify-between">
                <p className="flex align-center gap-sm">
                  <span className="graph-point blue-point" />
                  Principal:
                  <span className="bold">$25,000</span>
                </p>
                <p className="flex align-center gap-sm">
                  <span className="graph-point purple-point" />
                  Preliminary:
                  <span className="bold">$3,000</span>
                </p>
                <p className="flex align-center gap-sm">
                  <span className="graph-point yellow-point" />
                  Percents:
                  <span className="bold">$2,737</span>
                </p>
              </div>
            </section>
          </section>
        </div>
      </main>
    </div>
  );
};

export default LoanCalc;
