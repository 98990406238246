import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import eyeOpen from '../images/eye.png';
import eyeClosed from '../images/eye-closed.png';

const SignIn = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/');
  }

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleKeyDown = (event, toggleVisibility) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleVisibility();
    }
  };

  return (
    <div className="main-body signIn flex justify-between">
      <div className="form-body">
        <div className="form-body-container">
          <header>
            <Link to="/" className="logo">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <section className="form">
            <form onSubmit={handleSubmit} name="signForm" id="signForm">
              <h1>Sign In</h1>
              <p>We are happy to see you again!</p>
              <div className="form-group flex flex-col align-start">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="form-group flex flex-col align-start">
                <div class="password-wrap">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    onKeyDown={(event) => handleKeyDown(event, togglePasswordVisibility)}
                    className="eye"
                    role="button"
                    tabindex="0"
                  >
                    <img
                      id="eye"
                      src={passwordShown ? eyeClosed : eyeOpen}
                    />
                  </span>
                 </div>
              </div>
              <div className="form-group flex justify-end forgotPassword">
                <Link to="/forgot-password" className="forgot">
                  Click here
                </Link>
              </div>

                <button type="submit" className="btn">Sign In</button>

              <div className="form-group from-btn-small">
                <Link to="/sign-up">Click here</Link>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="form-image flex align-center justify-center">

      </div>
    </div>
  );
};

export default SignIn;
