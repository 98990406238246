import React, { useState } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';

import eyeOpen from '../images/eye.png';
import eyeClosed from '../images/eye-closed.png';

const SignIn = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/');
  }

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleKeyDown = (event, toggleVisibility) => {
    if (event.key === 'Enter') {
        toggleVisibility();
    }
  };

  return (
    <div className="main-body signIn flex justify-between">
      <div className="form-body">
        <div className="form-body-container">
          <header>
            <Link to="/" className="logo">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <section className="form">
            <form onSubmit={handleSubmit} name="signForm" id="signForm">
              <h1>Sign In</h1>
              <p>We are happy to see you again!</p>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="email@example.com"
                />
              </div>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="password">Password</label>
                <div className="password-wrap">
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    required
                    name="password"
                    placeholder=""
                  />
                  <img
                    id="eye"
                    src={passwordShown ? eyeClosed : eyeOpen}
                    onClick={togglePasswordVisibility}
                    onKeyDown={(event) => handleKeyDown(event, togglePasswordVisibility)}
                    className="eye"
                    alt="toggle password visibility"
                    tabIndex={0}
                  />
                </div>
              </div>
              <div className="form-group flex justify-end forgotPassword">
                <Link to="/forgot-password" className="forgot">
                  Forgot Password
                </Link>
              </div>
              <div className="form-group">
                <button type="submit" className="btn">Sign In</button>
              </div>
              <div className="form-group from-btn-small">
                <Link to="/sign-up">Sign Up</Link>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="form-image flex align-center justify-center">
       
      </div>
    </div>
  );
};

export default SignIn;
