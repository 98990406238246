import React, { useState } from 'react';
import './Traffic.css'; // Import the CSS file

 var currentTheme = 'light';
//see if user has toggled the them preferences and load proper setting



function toggleTheme(){
  // var themeButton = document.getElementById('theme');
  
 
  if(currentTheme === 'light'){
    currentTheme = 'dark';
    document.getElementById('theme').innerHTML = '&#9790;';
    document.getElementById('theme-container').setAttribute('class', 'dark');
    localStorage.setItem('themePreference', 'dark');
  } else {
    currentTheme = 'light';
    document.getElementById('theme').innerHTML = '☀';
    document.getElementById('theme-container').setAttribute('class', 'light');
    localStorage.setItem('themePreference', 'light');
  }
 
}

function checkThemePreferences(){
 //this is a tempfix/hack because when the local storage is not set it tries to find the element for the 'theme-ccontainer'
  if (window.location.href.indexOf("traffic") === -1) {
   return;
  }
 
  if (localStorage.themePreference) {
    document.getElementById('theme-container').setAttribute('class', localStorage.themePreference);
    currentTheme = localStorage.themePreference;
  } else {
    document.getElementById('theme').innerHTML = '☀';
    return;
  }

  if(localStorage.themePreference === 'dark'){
    document.getElementById('theme').innerHTML = '☾';
  } else{
    document.getElementById('theme').innerHTML = '☀';
  }
 // localStorage.getItem('themePreference');
}

function clearURL(){
  window.location.reload()
}

setTimeout(checkThemePreferences, 5);


function Traffic() {
  const [apiResponseData, setApiResponseData] = useState(null);

  const fetchTrafficData = () => {
    const input = document.getElementById('hostnames').value.trim();
    const hostnames = input.split('\n').map(hostname =>
      hostname.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/+$/, '')
    ).filter(hostname => hostname);

    if (hostnames.length === 0) {
      alert("Please enter valid hostnames.");
      return;
    }

    const requestData = { urls: hostnames };
    const url = 'uw-admin.api.userway.org';

    fetch(`https://${url}/api/v1/helper/traffic-api-temp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
    .then(response => response.json())
    .then(data => {
      setApiResponseData(data?.data ?? {});
      document.getElementById('downloadBtn').style.display = 'inline';
      document.getElementById('clearURLs').style.display = 'inline';
      document.getElementById('clearURLs').style.display = 'inline';
    })
    .catch(error => console.error('Error fetching traffic data:', error));
  };

  const downloadCSV = () => {
    if (!apiResponseData) return;

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Hostname,Total pageloads,Category\n";

    for (const [hostname, result] of Object.entries(apiResponseData)) {
      const { totalVisits, pagesPerVisit, category, success } = result;
      const row = [
        hostname,
        success ? Math.round(totalVisits * pagesPerVisit) : 'N/A',
        category ?? 'N/A'
      ].join(",");
      csvContent += row + "\n";
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `traffic_data_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="theme-container">
    <main id="trafficCalc">
    <div>
      <div class="theme-switcher">
        <button id="theme" title="Switch Theme" onClick={toggleTheme}>  </button>
      </div>
      <h1>Website Traffic Information</h1>
      
      <p>Enter a single or multiple hostnames or domains, one per line.</p>
      <textarea id="hostnames"></textarea>
      <button onClick={fetchTrafficData}>Submit</button>
      <button id="downloadBtn" onClick={downloadCSV} style={{ display: 'none' }}>Download as CSV</button>
      <button id="clearURLs" onClick={clearURL}>Clear URL(s)</button>
      
      <button id="clearURLs" onClick={clearURL}>Clear URL(s)</button>
      
      <div id="results">
        {apiResponseData && Object.entries(apiResponseData).map(([hostname, result]) => (
          <div key={hostname} className="result-item">
            <strong>Hostname:</strong> {hostname}<br />
            <strong>Total Pageloads:</strong> {result.success ? Math.round(result.totalVisits * result.pagesPerVisit).toLocaleString() : 'N/A'}<br />
            <strong>Category:</strong> {result.category ?? 'N/A'}
          </div>
        ))}
      </div>
      <p class="disclaimer"><strong>***FOR INTERNAL USE ONLY***</strong></p>
      <p class="disclaimer">This uses the same method UserWay has been using for estimation.
      It returns <strong>MONTHLY</strong> page views for each domain and can be used in CPQ.</p>
      <script>checkThemePreferences();</script>
    </div>
    </main>
    </div>
  );
}



export default Traffic;
