import React, { useState } from "react";
import "../App.css";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

import { Link } from "react-router-dom";

const Dashboard = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  const [showTransaction, setShowTransaction] = useState(false);

  const handleKeyDownTransaction = (event) => {
    if (event.key === 'Enter') {
        setShowTransaction(true);
    }
  };

  
  const [showSendMoneyStepOne, setshowSendMoneyStepOne] = useState(false);
  const [showSendMoneyStepTwo, setshowSendMoneyStepTwo] = useState(false);
  const [showSendMoneyStepThree, setshowSendMoneyStepThree] = useState(false);

  const handleKeyDownShowStepTwo = (event) => {
    if (event.key === 'Enter') {
      setshowSendMoneyStepOne(false);
      setshowSendMoneyStepTwo(true);
    }
  };

  const [sendingAmount, setSendingAmount] = useState('0');

  const handleAddPinButtonClick = (value) => {
    if (sendingAmount === '0') {
      if (value !== 0){
        setSendingAmount(value);
      }
    } else {
      setSendingAmount(sendingAmount + '' + value);
    }
  };

  const handleRemovePinButtonClick = () => {

    if (String(sendingAmount).length > 1) {
      setSendingAmount(Number(String(sendingAmount).slice(0, -1)));
    } else {
      if (sendingAmount !== '0'){
        setSendingAmount('0');
      }
    }

  };

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header className="flex align-center justify-between">
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage="dashboard"></Navbar>
          <NavbarMobile activePage="dashboard"></NavbarMobile>
        </div>
      </div>
      <main className="main">
        <header className="main-head flex justify-between align-center">
          <h1>Good morning, Amelia!</h1>
          <div className="dropdown profile-avatar">
            <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l" aria-label="Toggle profile options">
              <img src="images/profile-pic.svg" alt="" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex justify-between">
          <div className="main-content_left">
            <section>
              <h2>Cards</h2>
              <div className="cards flex align-center">
                <img src="images/card1.svg" alt="" />
                <img src="images/card2.svg" alt="" />
              </div>
            </section>
            <section className="recent-transactions-container">
              <h2>Recent Transactions</h2>
              <table name="recent-transactions" className="recent-transactions">
                <thead>
                  <tr disabled>
                    <th className="name">Name</th>
                    <th className="date mobile-hide">Date</th>
                    <th className="amount">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Airplane Tickets"
                    data-date="10 Jul, 2023"
                    data-amount="$2,456"
                    data-icon="images/airplane.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/airplane.svg" alt="" />
                      <span className="bold amount">Airplane Tickets</span>
                    </td>
                    <td className="date mobile-hide">10 Jul, 2023</td>
                    <td className="bold amount">$2,456</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Coffee Shop"
                    data-date="11 Jul, 2023"
                    data-amount="$10"
                    data-icon="images/coffee.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/coffee.svg" alt="" />
                      <span className="bold">Coffee Shop</span>
                    </td>
                    <td className="date mobile-hide">11 Jul, 2023</td>
                    <td className="bold amount">$10</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Marie M."
                    data-date="12 Jul, 2023"
                    data-amount="$2,456"
                    data-icon="images/marie.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/marie.svg" alt="" />
                      <span className="bold">Marie M.</span>
                    </td>
                    <td className="date mobile-hide">12 Jul, 2023</td>
                    <td className="bold amount">$200</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Gym"
                    data-date="11 Jul, 2023"
                    data-amount="$300"
                    data-icon="images/gym.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/gym.svg" alt="" />
                      <span className="bold">Gym</span>
                    </td>
                    <td className="date mobile-hide">11 Jul, 2023</td>
                    <td className="bold amount">$300</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Gas"
                    data-date="15 Jul, 2023"
                    data-amount="$39"
                    data-icon="images/gas.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/gas.svg" alt="" />
                      <span className="bold amount">Gas</span>
                    </td>
                    <td className="date mobile-hide">15 Jul, 2023</td>
                    <td className="bold amount">$39</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Car"
                    data-date="14 Jul, 2023"
                    data-amount="$200"
                    data-icon="images/car.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/car.svg" alt="" />
                      <span className="bold">Car</span>
                    </td>
                    <td className="date mobile-hide">14 Jul, 2023</td>
                    <td className="bold amount">$200</td>
                  </tr>
                  <tr
                    onClick={() => setShowTransaction(true)}
                    onKeyDown={(event) => handleKeyDownTransaction(event)}
                    tabIndex="0"
                    data-name="Airplane Tickets"
                    data-date="10 Jul, 2023"
                    data-amount="$2,456"
                    data-icon="images/airplane.svg"
                  >
                    <td className="flex align-center gap-sm name">
                      <img src="images/airplane.svg" alt="" />
                      <span className="bold amount">Airplane Tickets</span>
                    </td>
                    <td className="date mobile-hide">10 Jul, 2023</td>
                    <td className="bold amount">$2,456</td>
                  </tr>
                </tbody>
                <tbody></tbody>
              </table>
            </section>
          </div>
          <div id="content-right" className="main-content_right">
            <section id="send-money-one" className="modal-container" style={{ transform: showSendMoneyStepOne ? 'translateX(0)' : 'translateX(100%)', display: showSendMoneyStepOne ? 'block' : 'none' }}>
              <div className="">
                <header>
                  <button
                    onClick={() => setshowSendMoneyStepOne(false)}
                    id="back-btn1"
                    className="back-btn flex align-center"
                    aria-label="Go back"
                  >
                    <img src="images/arrow-back.svg" alt="" />
                  </button>
                </header>
                <div className="send-money flex flex-col justify-center align-start">
                  <h3>Send Money</h3>
                  <div className="search-wrap">
                    <p>Write name, number of the card or phone number</p>
                    <div className="input-wrap flex align-center">
                      <img src="images/search.svg" alt="" />
                      <input
                        type="text"
                        id="last-transfers-search"
                        placeholder="Start searching here"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-cards">
                  <h5>My Cards</h5>
                  <div className="flex align-center gap-m">
                    <img src="images/cd.svg" alt="" />
                    <span>On my card 1234 **** 9000</span>
                  </div>
                </div>
                <div className="last-transfers">
                  <h5>Last Transfers</h5>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img1.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img2.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img3.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img4.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                </div>
                <div className="last-transfers starting-a">
                  <h5>A</h5>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img5.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img6.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img3.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                </div>
                <div className="last-transfers starting-b">
                  <h5>B</h5>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img7.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                  <div
                    tabIndex={0}
                    onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(true);} }
                    onKeyDown={(event) => handleKeyDownShowStepTwo(event)}
                    className="last-transfers-item flex align-center gap-l"
                  >
                    <img src="images/lt-img4.png" alt="" />
                    <span className="flex flex-col">
                      <span>Pintér B.</span>
                      <span className="text-light">1234 5678 **** 9000</span>
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section id="send-money-two" className="modal-container" style={{ transform: showSendMoneyStepTwo ? 'translateX(0)' : 'translateX(100%)', display: showSendMoneyStepTwo ? 'block' : 'none' }}>
              <div className="">
                <header>
                  <button
                    onClick={() => {setshowSendMoneyStepTwo(false); setshowSendMoneyStepOne(true);} }
                    id="back-btn2"
                    className="back-btn flex align-center"
                    aria-label="Go back"
                  >
                    <img src="images/arrow-back.svg" alt="" /> Go Back
                  </button>
                </header>
                <div className="send-money flex flex-col justify-center align-center">
                  <h3 className="text-center">You are sending to:</h3>
                  <div className="sender">
                    <div className="flex align-center gap-l">
                      <img src="images/lt-img4.png" alt="" />
                      <span className="flex flex-col">
                        <span className="bold">Pintér B.</span>
                        <span className="text-light">1234 5678 **** 9000</span>
                      </span>
                    </div>
                  </div>
                  <div className="sender-amount flex flex-col align-center gap-xl justify-center">
                    <div className="sender-amount-value">
                      <h3>
                        $<span id="sending-amount">{sendingAmount}</span>
                      </h3>
                    </div>
                    <div className="card-balance flex align-center gap-sm">
                      <img src="images/cardsvg.svg" alt="" />
                      <span>Card Balance:</span>
                      <span className="bold">$242,456</span>
                    </div>
                  </div>
                  <div className="numpad">
                    <button
                      onClick={() => {setshowSendMoneyStepOne(false); setshowSendMoneyStepTwo(false); setshowSendMoneyStepThree(true); setSendingAmount(0);}}
                      id="send-amount"
                      className="btn numpad-btn"
                    >
                      Send Money
                    </button>
                    <div className="numpad-keys prevent-select">
                      <button onClick={() => handleAddPinButtonClick(1)} className="numpad-btn" value="1" aria-label="1">
                        1
                      </button>
                      <button onClick={() => handleAddPinButtonClick(2)} className="numpad-btn" value="2" aria-label="2">
                        2
                      </button>
                      <button onClick={() => handleAddPinButtonClick(3)} className="numpad-btn" value="3" aria-label="3">
                        3
                      </button>
                      <button onClick={() => handleAddPinButtonClick(4)} className="numpad-btn" value="4" aria-label="4">
                        4
                      </button>
                      <button onClick={() => handleAddPinButtonClick(5)} className="numpad-btn" value="5" aria-label="5">
                        5
                      </button>
                      <button onClick={() => handleAddPinButtonClick(6)} className="numpad-btn" value="6" aria-label="6">
                        6
                      </button>
                      <button onClick={() => handleAddPinButtonClick(7)} className="numpad-btn" value="7" aria-label="7">
                        7
                      </button>
                      <button onClick={() => handleAddPinButtonClick(8)} className="numpad-btn" value="8" aria-label="8">
                        8
                      </button>
                      <button onClick={() => handleAddPinButtonClick(9)} className="numpad-btn" value="9" aria-label="9">
                        9
                      </button>
                      <button className="numpad-empty-btn"></button>
                      <button onClick={() => handleAddPinButtonClick(0)} className="numpad-btn" value="0" aria-label="0">
                        0
                      </button>
                      <button
                        onClick={() => handleRemovePinButtonClick()}
                        className="numpad-remove-btn numpad-btn"
                        id="numpad-remove-btn"
                        aria-label="Remove last digit"
                      >
                        <img src="images/tab-right.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="send-money-three" className="modal-container" style={{ transform: showSendMoneyStepThree ? 'translateX(0)' : 'translateX(100%)', display: showSendMoneyStepThree ? 'block' : 'none' }}>
              <div className="">
                <div className="money-transfered flex flex-col align-center justify-center gap-l">
                  <h3 className="text-center">
                    Money has been successfully transferred!
                  </h3>
                  <p className="text-center">
                    The money has been successfully transferred and will reflect
                    in your transactions list soon.
                  </p>
                  <button onClick={() => {setshowSendMoneyStepThree(false)}} id="moneyTransfered" className="btn">
                    Got It!
                  </button>
                </div>
              </div>
            </section>

            <section id="transaction-details" className="modal-container" style={{ transform: showTransaction ? 'translateX(0)' : 'translateX(100%)', display: showTransaction ? 'block' : 'none' }}>
              <div className="upper">
                <header>
                  <button
                  onClick={() => setShowTransaction(false)}
                    id="back-btn"
                    className="back-btn flex align-center"
                    aria-label="Go back"
                  >
                    <img src="images/arrow-back.svg" alt="" /> Go Back
                  </button>
                </header>
                <div className="flex flex-col justify-center gap-m align-center">
                  <img id="transaction-icon" src="images/coffee.svg" alt="" />
                  <h3 id="transaction-name">Coffee Shop</h3>
                  <p id="transaction-date">10 Jul, 2023</p>
                  <span className="tx-status">Pending</span>
                </div>
              </div>
              <div className="lower">
                <div className="tx-income card-bordered flex flex-col align-center justify-between">
                  <h2 id="transaction-amount">$10</h2>
                  <span className="outcome flex align-center gap-sm">
                    <img src="images/trend-down.svg" alt="" />
                    <span className="bold">Outcome</span>
                  </span>
                </div>
                <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
                  <img src="images/left-balance.svg" alt="" />
                  <p>
                    <span className="opacity-05">Left Balance:</span>
                    <span className="bold opacity-1">&nbsp; $242,456</span>
                  </p>
                </div>
                <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
                  <img src="images/apple.svg" alt="" />
                  <p>
                    <span className="opacity-05">Payment Method:</span>
                    <span className="bold opacity-1">&nbsp; Apple Pay</span>
                  </p>
                </div>
                <div className="left-balance card-bordered card-sm p-12 flex align-center gap-m">
                  <img src="images/location.svg" alt="" />
                  <p>
                    <span className="opacity-05">Address:</span>
                    <span className="bold opacity-1">
                      &nbsp; 1901 Thornridge Cir. Shiloh
                    </span>
                  </p>
                </div>
              </div>
            </section>
            <section>
              <h2>Current Balance</h2>
              <div className="actual-balance flex flex-col align-center justify-center">
                <p>Actual Balance</p>
                <h3>
                  <img src="images/coin.svg" alt="" />
                  $242,456
                </h3>
                <div className="income-outcome flex align-center">
                  <div className="income flex align-center gap-sm">
                    <img src="images/trend-up.svg" alt="" />
                    <span>
                      Income: <span className="bold">$20,324</span>
                    </span>
                  </div>
                  <div className="outcome flex align-center gap-sm">
                    <img src="images/trend-down.svg" alt="" />
                    <span>
                      Outcome: <span className="bold">$20,324</span>
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <section className="send-receive-container flex justify-between align-center">
              <button
                onClick={() => setshowSendMoneyStepOne(true)}
                id="send-money"
                className="send flex justify-between align-center"
              >
                <span className="bold">Send Money</span>
                <img src="images/send-money.svg" alt="" />
              </button>
              <button className="receive flex justify-between align-center">
                <span className="bold">Receive Money</span>
                <img src="images/send-money.svg" alt="" />
              </button>
            </section>
            <section className="loans-section flex flex-col align-center gap-sm">
              <img src="images/dollar-square.svg" alt="" />
              <h4>You don’t have any loans now.</h4>
              <p>If you like to take a loan, press the button to know more</p>
              <Link to="/loan-calc" className="btn-small">Take a loan</Link>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
