import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';

const ForgotPassword = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/recover-password');
  }

  return (
    <div className="main-body signIn flex justify-between">
      <div className="form-body">
        <div className="form-body-container">
          <header>
            <Link to="/sign-in" className="back-btn flex align-center">
              <img src="./images/arrow-back.png" /> Go Back
            </Link>
          </header>
          <section id="form" className="form">
            <form onSubmit={handleSubmit} name="forgotForm" id="forgotForm">
              <h1>Forgot your password?</h1>
              <p>
                Write your email and we'll send you instructions on how to
                recover your password.
              </p>
              <div className="form-group flex flex-col align-start">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="email@example.com"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn">
                    Send the instructions
                  </button>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div className="form-image flex align-center justify-center">
        
      </div>
    </div>
  );
};

export default ForgotPassword;
