import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

import {useNavigate} from 'react-router-dom';

const LoanInfo = () => {

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    navigate('/loan-success');
  }

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage=""></Navbar>
          <NavbarMobile activePage=""></NavbarMobile>
        </div>
      </div>
      <main className="main">
        <header className="main-head flex justify-between align-center">
          <h1>Loans</h1>
          <div className="dropdown profile-avatar">
          <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l" aria-label="Toggle profile options">
              <img src="images/profile-pic.svg" alt="" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex justify-between">
          <section className="loans-form loans-calculator">
            <header>
              <h4>Personal Information</h4>
            </header>
            <section id="form" className="form card-bordered">
              <form onSubmit={handleSubmit}>
                <h5>Sign Up</h5>
                <div className="form-group flex flex-col align-start">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    required
                    placeholder="First Name"
                  />
                </div>
                <div className="form-group flex flex-col align-start">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    required
                    placeholder="Last Name"
                  />
                </div>
                <div className="form-group flex flex-col align-start">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="email@example.com"
                  />
                </div>
                <div className="form-group flex flex-col align-start">
                  <label htmlFor="phone_number">Phone Number</label>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    required
                    placeholder="xxx xx xx xxx"
                  />
                </div>
                <div className="form-group flex flex-col justify-start">
                  <label htmlFor="loan-length-select">State</label>
                  <div className="select-container">
                    <select required className="custom-select" id="loan-length-select">
                      <option value="" selected="selected">
                        Select State
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    <div className="dropdown-icon">
                      <img src="images/arrow-down.svg" alt="down"/>
                    </div>
                  </div>
                </div>
                <div className="form-group flex flex-col justify-start">
                  <label htmlFor="loan-notes">Notes</label>
                  <textarea
                    name="loan-notes"
                    id="loan-notes"
                    cols={30}
                    rows={5}
                    defaultValue={""}
                  />
                </div>
                <div className="form-group flex justify-start">
                  <input
                    className="checkbox"
                    type="checkbox"
                    required
                    name="terms"
                    id="terms"
                  />
                  <span>
                    <label htmlFor="terms" className="font-weight-500">
                      Agree to{" "}
                    </label>
                    <a href="https://www.google.com" className="bold">
                      Terms &amp; Conditions
                    </a>{" "}
                    and
                    <a href="https://www.google.com" className="bold">
                      Privacy Policy.
                    </a>
                  </span>
                </div>
                <div className="form-group">
                  <button id="loanConfirmBtn" type="submit" className="btn">
                    Apply Now
                  </button>
                </div>
              </form>
            </section>
          </section>
          <section className="loans-information">
            <header>
              <h4>Loan Information</h4>
            </header>
            <section className="loan-insights flex flex-col">
              <div className="loan-insights-data flex align-center justify-between">
                <div>
                  <h5>2 years</h5>
                  <p>
                    Regular payment: <span className="bold">$1280</span>
                  </p>
                </div>
                <div>
                  <h5>%5</h5>
                  <p>Interest rate</p>
                </div>
              </div>
              <img
                className="loan-insights-graph"
                src="images/graph.svg"
                alt="Graph"
              />
              <div className="graph-points flex align-center justify-between">
                <p className="flex align-center gap-sm">
                  <span className="graph-point blue-point" />
                  Principal:
                  <span className="bold">$25,000</span>
                </p>
                <p className="flex align-center gap-sm">
                  <span className="graph-point purple-point" />
                  Preliminary:
                  <span className="bold">$3,000</span>
                </p>
                <p className="flex align-center gap-sm">
                  <span className="graph-point yellow-point" />
                  Percents:
                  <span className="bold">$2,737</span>
                </p>
              </div>
            </section>
          </section>
        </div>
      </main>
    </div>
  );
};

export default LoanInfo;
