import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./NavBarDesktop";
import NavbarMobile from "./NavBarMobile";

const Cards = () => {

  const [dropdownShownSignOut, setDropdownShownSignOut] = useState(false);
  const [dropdownShownChangePin, setDropdownShownChangePin] = useState(false);

  const [showChangePin, setShowChangePin] = useState(false);
  const [showChangePinConfirmation, setShowChangePinConfirmation] = useState(false);

  const [pinCode, setPinCode] = useState(0);

  const handleAddPinButtonClick = () => {
    if (pinCode < 3) {
      setPinCode(pinCode + 1);
    } else {
      setPinCode(0);
      setShowChangePin(false);
      setShowChangePinConfirmation(true);
    }
  };

  const handleRemovePinButtonClick = () => {
    if (pinCode > 0) {
      setPinCode(pinCode - 1);
    }
  };

  return (
    <div className="dashboard-container flex">
      <div className="sidebar-container">
        <div className="sidebar">
          <header
            style={{ width: "100%" }}
            className="flex align-center justify-between"
          >
            <Link to="/" className="logo text-white">
              Banking <span className="text-green">App</span>
            </Link>
          </header>
          <Navbar activePage="cards"></Navbar>
          <NavbarMobile activePage="cards"></NavbarMobile>
        </div>
      </div>
      <main className="main">
        {/* ----- Modals (Send Money 2) ------ */}
        <section id="send-money-two" className="modal-container" style={{ transform: showChangePin ? 'translateX(0)' : 'translateX(100%)', display: showChangePin ? 'block' : 'none' }}>
          <div className="">
            <header>
              <button
                id="back-btn2"
                disabled=""
                className="back-btn flex align-center"
                onClick={() => {
                  setShowChangePin(!showChangePin);
                }}
              >
                <img src="./images/arrow-back.svg" /> Go Back
              </button>
            </header>
            <div className="send-money flex flex-col justify-center align-center">
              <h3 className="text-center">PIN-Code Settings</h3>
              <p>Write down new PIN-Code</p>
              <div className="pin-code flex align-center gap-sm">
                <span className="pin" style={{ background: pinCode <= 0 ? '#f0eef5' : 'rgb(151, 71, 255)' }} />
                <span className="pin" style={{ background: pinCode <= 1 ? '#f0eef5' : 'rgb(151, 71, 255)' }} />
                <span className="pin" style={{ background: pinCode <= 2 ? '#f0eef5' : 'rgb(151, 71, 255)' }} />
                <span className="pin" style={{ background: pinCode <= 3 ? '#f0eef5' : 'rgb(151, 71, 255)' }} />
              </div>
              <div className="numpad">
                <div className="numpad-keys">
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={1}>
                    1
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={2}>
                    2
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={3}>
                    3
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={4}>
                    4
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={5}>
                    5
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={6}>
                    6
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={7}>
                    7
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={8}>
                    8
                  </button>
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={9}>
                    9
                  </button>
                  <button disabled className="numpad-empty-btn" />
                  <button onClick={handleAddPinButtonClick} className="numpad-btn" value={0}>
                    0
                  </button>
                  <button
                    onClick={handleRemovePinButtonClick}
                    className="numpad-remove-btn numpad-btn"
                    id="numpad-remove-btn"
                  >
                    <img src="./images/tab-right.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="send-money-three" className="modal-container" style={{ transform: showChangePinConfirmation ? 'translateX(0)' : 'translateX(100%)', display: showChangePinConfirmation ? 'block' : 'none' }}>
          <div className="">
            <div className="money-transfered flex flex-col align-center justify-center gap-l">
              <h3 className="text-center">
                Your PIN-Code was successfully changed!
              </h3>
              <p className="text-center">
                Now you can yse your new PIN-Code with your card and in the app.
              </p>
              <button onClick={() => setShowChangePinConfirmation(!showChangePinConfirmation)} id="pinChangedBtn" className="btn">
                Got It!
              </button>
            </div>
          </div>
        </section>
        {/* Modal End */}
        <header className="main-head flex justify-between align-center">
          <h1>Cards</h1>
          <div className="dropdown profile-avatar">
            <button onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} className="dropdown-btn flex align-center gap-l">
              <img src="images/profile-pic.svg" />
            </button>
            {dropdownShownSignOut && (
            <div className="dropdown-content">
              <Link onClick={() => setDropdownShownSignOut(!dropdownShownSignOut)} to="/sign-in" id="logoutBtn" className="dropdown-option btn">
                Logout
              </Link>
            </div>
            )}
          </div>
        </header>
        <div className="main-content flex flex-col justify-between">
          <div className="card-details card-bordered">
            <header className="flex justify-between align-center">
              <h4>Card Details #1</h4>
              <div className="dropdown">
                <button onClick={() => setDropdownShownChangePin(!dropdownShownChangePin)} className="dropdown-btn flex align-center gap-l">
                  <img src="./images/three-dots.svg" />
                </button>
                {dropdownShownChangePin && (
                <div className="dropdown-content">
                  <button onClick={() => {
                    setDropdownShownChangePin(!dropdownShownChangePin);
                    setShowChangePin(!showChangePin);
                  }
                  } id="changePinBtn" className="dropdown-option btn">
                    Change PIN-Code
                  </button>
                  <button className="dropdown-option btn">
                    Send a statement by card
                  </button>
                  <button className="dropdown-option btn">
                    Block the card
                  </button>
                </div>
                )}
              </div>
            </header>
            <div className="card-row flex align-center justify-between">
              <div className="card-img">
                <img src="./images/card1.svg" />
              </div>
              <div className="number-date flex flex-col gap-l">
                <div
                  style={{
                    borderBottom: "1px solid #f0eef5",
                    paddingBottom: "1.1rem",
                  }}
                  className="flex flex-col gap-m"
                >
                  <h5>CARD NUMBER</h5>
                  <p>**** **** **** 9000</p>
                </div>
                <div className="flex flex-col gap-m">
                  <h5>Expire Date</h5>
                  <p>12/24</p>
                </div>
              </div>
              <div className="spending-limit flex flex-col gap-m">
                <h5>Spending Limits</h5>
                <div className="bar">
                  <span className="fill" />
                </div>
                <div className="flex align-center justify-between">
                  <span className="bold">$100,492 spent of $400,000</span>
                  <span>25%</span>
                </div>
                <div className="flex align-center justify-between">
                  <span className="bold">Use spending limit</span>
                  <label className="switch" htmlFor="checkbox1">
                    <input type="checkbox" id="checkbox1" />
                    <div className="slider round" />
                  </label>
                </div>
              </div>
            </div>
            <div className="card-row cash-flow flex align-center justify-between">
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-details card-bordered">
            <header className="flex justify-between align-center">
              <h4>Card Details #2</h4>
            </header>
            <div className="card-row flex align-center justify-between">
              <div className="card-img">
                <img src="./images/card2.svg" />
              </div>
              <div className="number-date flex flex-col gap-l">
                <div
                  style={{
                    borderBottom: "1px solid #f0eef5",
                    paddingBottom: "1.1rem",
                  }}
                  className="flex flex-col gap-m"
                >
                  <h5>CARD NUMBER</h5>
                  <p>**** **** **** 9000</p>
                </div>
                <div className="flex flex-col gap-m">
                  <h5>Expire Date</h5>
                  <p>12/24</p>
                </div>
              </div>
              <div className="spending-limit flex flex-col gap-m">
                <h5>Spending Limits</h5>
                <div className="bar">
                  <span className="fill fill-0" />
                </div>
                <div className="flex align-center justify-between">
                  <span className="bold">$100,492 spent of $400,000</span>
                  <span>0%</span>
                </div>
                <div className="flex align-center justify-between">
                  <span className="bold">Use spending limit</span>
                  <label className="switch" htmlFor="checkbox2">
                    <input type="checkbox" id="checkbox2" />
                    <div className="slider round" />
                  </label>
                </div>
              </div>
            </div>
            <div className="card-row cash-flow flex align-center justify-between">
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
              <div className="cash-flow-item flex align-center gap-l">
                <img src="./images/balance-icon.svg" />
                <div>
                  <h4>Card Balance</h4>
                  <p>$242,456</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cards;
